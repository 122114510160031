<template>
  <div class="web_box">
    <van-nav-bar title="商城" fixed class="header_class" :border="false">
      <!-- <div class="back_icon" slot="left" /> -->
    </van-nav-bar>
    <div class="bg bgset"></div>
    <div class="classification">
      <div class="classification_item bgset" @click="toMallOption('frequency')">
        <span class="item_icon item_icon1 bgset"></span>
        <p>频率商城</p>
      </div>
      <div class="classification_item bgset" @click="toMallOption('hardware')">
        <span class="item_icon item_icon2 bgset"></span>
        <p>硬件商城</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toMallOption(text) {
      if (text == "hardware") {
        this.$router.push("/mallOption");
      } else if (text == "frequency") {
        this.$router.push("/formulaShop");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bgset {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.bg {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: -1;
  background-image: url("../../assets/mall/mall_bg.png");
}
.classification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  margin: 266px auto 0;
  .classification_item {
    width: 332px;
    height: 772px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-image: url("../../assets/mall/item.bg.png");
    .item_icon {
      width: 60px;
      height: 60px;
      margin-top: 295px;
    }
    .item_icon1 {
      background-image: url("../../assets/mall/item_icon2.png");
    }
    .item_icon2 {
      background-image: url("../../assets/mall/item_icon1.png");
    }
    p {
      font-size: 30px;
      color: #ffffff;
      margin-top: 30px;
    }
  }
}
</style>
